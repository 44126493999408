const getEnvironment = () => {
  // Check for explicit environment variable
  if (process.env.REACT_APP_ENV) {
    return process.env.REACT_APP_ENV;
  }
  // Check if we're in development based on React's environment variable
  if (process.env.NODE_ENV === 'development') {
    return 'development';
  } else if (process.env.REACT_APP_ENV === 'ngrok') {
    return 'ngrok';
  }
  return 'production';
};

const config = {
  development: {
    API_URL: 'https://localhost:8443',
    FRONTEND_URL: 'https://localhost',
  },
  production: {
    API_URL: 'https://api.stylegenai.com',
    FRONTEND_URL: 'https://app.stylegenai.com',
  },
  ngrok: {
    API_URL: 'http://127.0.0.1:8000',
    FRONTEND_URL: 'https://aware-guppy-honestly.ngrok-free.app',
  }
};

const environment = getEnvironment();
console.log('Current environment:', environment); // For debugging
console.log('FRONTEND_URL:', config[environment].FRONTEND_URL); // For debugging
console.log('API URL:', config[environment].API_URL); // For debugging

export const { API_URL, FRONTEND_URL } = config[environment]; 