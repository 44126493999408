import React, { useState, useCallback, memo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, Transition } from '@headlessui/react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logo from '../assets/logo_circular.png';

const NavLink = memo(({ to, children }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      className={`px-4 py-2 text-sm font-medium transition-colors duration-200 ${
        isActive 
          ? 'text-purple-600 border-b-2 border-purple-600' 
          : 'text-gray-600 hover:text-purple-600'
      }`}
    >
      {children}
    </Link>
  );
});

NavLink.displayName = 'NavLink';

const UserMenu = memo(({ username, avatar, onLogout }) => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button className="flex items-center space-x-3 focus:outline-none">
        <span className="text-sm font-medium text-gray-700">{username}</span>
        <motion.div 
          whileHover={{ scale: 1.05 }}
          className="h-10 w-10 rounded-full overflow-hidden ring-2 ring-purple-100 hover:ring-purple-200"
        >
          {avatar ? (
            <img
              src={avatar}
              alt="User avatar"
              className="h-full w-full object-cover"
            />
          ) : (
            <div className="w-full h-full bg-gradient-to-r from-purple-400 to-pink-500 flex items-center justify-center text-white text-2xl font-bold">
              {username ? username[0] : ''}
            </div>
          )}
        </motion.div>
      </Menu.Button>
      
      <Transition
        enter="transition duration-200 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-in"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none py-2">
          <Menu.Item>
            {({ active }) => (
              <Link
                to="/profile"
                className={`${
                  active ? 'bg-purple-50 text-purple-600' : 'text-gray-700'
                } flex items-center px-4 py-2 text-sm`}
              >
                My Profile
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <a
                href="/pricing"
                className={`${
                  active ? 'bg-purple-50 text-purple-600' : 'text-gray-700'
                } flex items-center px-4 py-2 text-sm`}
              >
                Manage subscription
              </a>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <a
                href="/feedback"
                className={`${
                  active ? 'bg-purple-50 text-purple-600' : 'text-gray-700'
                } flex items-center px-4 py-2 text-sm`}
              >
                Send us feedback
              </a>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <a
                href="#"
                className={`${
                  active ? 'bg-purple-50 text-purple-600' : 'text-gray-700'
                } flex items-center px-4 py-2 text-sm`}
              >
                Help
              </a>
            )}
          </Menu.Item>
          <div className="my-2 border-t border-gray-100" />
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={onLogout}
                className={`${
                  active ? 'bg-purple-50 text-purple-600' : 'text-red-700'
                } flex w-full items-center px-4 py-2 text-sm`}
              >
                Sign out
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
});

UserMenu.displayName = 'UserMenu';

const Layout = ({ children, isLoggedIn, onLogout, username, avatar }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Memoized handlers for better performance
  const handleLogoClick = useCallback((e) => {
    e.preventDefault();
    if (isLoggedIn) {
      navigate('/');
    } else {
      window.location.href = 'https://stylegenai.com';
    }
  }, [isLoggedIn, navigate]);

  const showNavigation = !isLoggedIn && location.pathname !== '/login';

  // Animation variants
  const fadeIn = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <motion.header 
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        className="shadow-sm h-20 fixed inset-x-0 top-0 z-50 bg-white/95 backdrop-blur-sm"
      >
        <nav aria-label="Global" className="container mx-auto flex items-center justify-between p-6 lg:px-8 h-full">
          <motion.div 
            whileHover={{ scale: 1.05 }}
            className="flex lg:flex-1"
          >
            <a 
              href="#" 
              onClick={handleLogoClick} 
              className="flex items-center group"
              aria-label="StyleGen AI Home"
            >
              <img 
                src={logo} 
                alt="StyleGen AI Logo" 
                className="h-12 w-12 rounded-full object-cover transition-transform duration-300 group-hover:rotate-12" 
              />
              <span className="ml-2 text-xl font-semibold text-gray-800 transition-colors duration-200 group-hover:text-purple-600">
                StyleGen AI
              </span>
            </a>
          </motion.div>

          {/* Desktop navigation */}
          {isLoggedIn && (
            <div className="hidden lg:flex lg:gap-x-6">
              <NavLink to="/">Home</NavLink>
              <NavLink to="/my-closet">My closet</NavLink>
              <NavLink to="/favorites">Favorites</NavLink>
            </div>
          )}

          {/* Mobile menu button */}
          {(showNavigation || isLoggedIn) && (
            <div className="flex lg:hidden">
              <button
                type="button"
                onClick={() => setMobileMenuOpen(true)}
                className="rounded-md p-2.5 text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                aria-label="Open main menu"
              >
                <Bars3Icon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
          )}

          {showNavigation && (
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <motion.div whileHover={{ scale: 1.05 }}>
                <Link 
                  to="/login"
                  className="button-gradient"
                  aria-label="Sign in to StyleGen AI"
                >
                  Sign in
                </Link>
              </motion.div>
            </div>
          )}

          {isLoggedIn && (
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <UserMenu 
                username={username}
                avatar={avatar}
                onLogout={onLogout}
              />
            </div>
          )}
        </nav>

        {/* Mobile menu dialog */}
        <AnimatePresence>
          {mobileMenuOpen && (
            <Dialog 
              static 
              open={mobileMenuOpen} 
              onClose={setMobileMenuOpen} 
              className="lg:hidden"
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 z-50 bg-black/30 backdrop-blur-sm"
              />
              <motion.div
                initial={{ x: '100%' }}
                animate={{ x: 0 }}
                exit={{ x: '100%' }}
                transition={{ type: 'spring', damping: 25, stiffness: 200 }}
              >
                <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                  <div className="flex items-center justify-between">
                    <a href="#" className="-m-1.5 p-1.5">
                      <span className="sr-only">StyleGen AI</span>
                    </a>
                    <button
                      type="button"
                      onClick={() => setMobileMenuOpen(false)}
                      className="rounded-md p-2.5 text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                      aria-label="Close menu"
                    >
                      <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                    </button>
                  </div>
                  <div className="mt-6 flow-root">
                    <div className="-my-6 divide-y divide-gray-500/10">
                      <div className="py-6">
                        {isLoggedIn ? (
                          <div className="space-y-2">
                            <div className="w-12 h-12 rounded-full overflow-hidden group-hover:opacity-75 transition-opacity duration-200">
                              {avatar ? (
                                <img
                                  src={avatar}
                                  alt="User avatar"
                                  className="h-full w-full rounded-full object-cover"
                                />
                              ) : (
                                <div className="w-full h-full bg-gradient-to-r from-purple-400 to-pink-500 flex items-center justify-center text-white text-2xl font-bold">
                                  {username ? username[0] : ''}
                                </div>
                              )}
                              <span className="text-sm font-medium text-gray-700">{username}</span>
                            </div>
                            <Link
                              to="/"
                              className="mobile-menu-item"
                              onClick={() => setMobileMenuOpen(false)}
                            >
                              Home
                            </Link>
                            <Link
                              to="/my-closet"
                              className="mobile-menu-item"
                              onClick={() => setMobileMenuOpen(false)}
                            >
                              My closet
                            </Link>
                            <Link
                              to="/favorites"
                              className="mobile-menu-item"
                              onClick={() => setMobileMenuOpen(false)}
                            >
                              Favorites
                            </Link>
                            
                            <Link
                              to="/profile"
                              className="mobile-menu-item"
                              onClick={() => setMobileMenuOpen(false)}
                            >
                              My Profile
                            </Link>
                            <a href="#" className="mobile-menu-item">
                              Send us feedback
                            </a>
                            <a href="#" className="mobile-menu-item">
                              Manage subscription
                            </a>
                            <a href="#" className="mobile-menu-item">
                              Help
                            </a>
                            <div className="my-2 border-t border-gray-100" />
                            <button
                              onClick={() => {
                                onLogout();
                                setMobileMenuOpen(false);
                              }}
                              className="mobile-menu-item w-full text-left text-red-600 hover:text-red-700 hover:bg-red-50"
                            >
                              Sign out
                            </button>
                          </div>
                        ) : showNavigation ? (
                          <Link
                            to="/login"
                            className="mobile-menu-item"
                            onClick={() => setMobileMenuOpen(false)}
                          >
                            Sign in
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </motion.div>
            </Dialog>
          )}
        </AnimatePresence>
      </motion.header>

      <main className="flex-grow pt-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          {children}
        </motion.div>
      </main>

      <footer className="bg-gray-900">
        <motion.div 
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="container mx-auto px-4 py-4 text-center text-gray-400"
        >
          © {new Date().getFullYear()} StyleGen AI - All rights reserved.
        </motion.div>
      </footer>
    </div>
  );
};

export default memo(Layout);
