import React, { useState, useRef, useCallback, useEffect } from 'react';
import { client } from '../axiosClient';
import styled from 'styled-components';
import { Button } from './ui/button';
import Joyride from 'react-joyride';
import { useStartTour } from '../hooks/startTour';
import { useToast } from './ui/use-toast';

const OutfitUpload = ({ onUploadSuccess }) => {
  const {toast} = useToast();
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  const dragCounter = useRef(0);
  const [loadingText, setLoadingText] = useState('');
  const loadingTexts = ['Removing Background', 'Analyzing Images', 'Processing', 'This might take a while...'];
  const loadingTextIndex = useRef(0);
  const { runTour, steps, startTour, config } = useStartTour('outfitUpload');

  useEffect(() => {
    const hasSeenTour = localStorage.getItem('hasSeenOutfitUploadTour');
    if (!hasSeenTour) {
      startTour();
      localStorage.setItem('hasSeenOutfitUploadTour', 'true');
    }
  }, [startTour]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if (status === 'finished' || status === 'skipped') {
      localStorage.setItem('hasSeenOutfitUploadTour', 'true');
    }
  };

  useEffect(() => {
    let interval;
    if (uploading) {
      interval = setInterval(() => {
        setLoadingText(loadingTexts[loadingTextIndex.current]);
        loadingTextIndex.current = (loadingTextIndex.current + 1) % loadingTexts.length;
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [uploading]);

  const handleImageChange = (e) => {
    const files = e.target.files || e.dataTransfer.files;
    const newImages = Array.from(files);
    
    if (images.length + newImages.length > 10) {
      setErrors(prevErrors => ({
        ...prevErrors,
        imageLimit: "Upload up to 10 images at once"
      }));
      return;
    }
    
    setImages(prevImages => [...prevImages, ...newImages]);
    setErrors(prevErrors => ({
      ...prevErrors,
      imageLimit: null
    }));
  };

  const handleRemoveImage = (index) => {
    setImages(prevImages => prevImages.filter((_, i) => i !== index));
  };

  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragIn = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  }, []);

  const handleDragOut = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setIsDragging(false);
    }
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    dragCounter.current = 0;
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleImageChange(e);
      e.dataTransfer.clearData();
    }
  }, [handleImageChange]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (images.length === 0) return;

    setUploading(true);
    setErrors({});
    const formData = new FormData();
    images.forEach((image, index) => {
      formData.append(`image_${index}`, image);
    });

    try {
      const response = await client.post('/api/upload-outfits/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 300000, // 5 minutes
      });
      
      if (response.data) {
        console.log('Success:', response.data);
        setImages([]);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }

        toast({
          title: 'Upload Successful',
          description: 'Your outfits have been uploaded successfully!',
          variant: 'success',
          duration: 5000,
        });

        if (onUploadSuccess) {
          onUploadSuccess();
        }
        return;
      }
    } catch (error) {
      console.error('Error uploading images:', error);
      if (error.response) {
        if (error.response.status === 403) {
          const errorMessage = error.response.data?.detail || 'Subscription error';
          setErrors({ subscription: errorMessage });
          toast({
            title: 'Subscription Error',
            description: errorMessage,
            variant: 'destructive',
            duration: 5000,
          });
        } else {
          const errorMessage = error.response.data?.detail || 'An error occurred while uploading.';
          setErrors({ general: errorMessage });
          toast({
            title: 'Upload Error',
            description: errorMessage,
            variant: 'destructive',
            duration: 5000,
          });
        }
      } else if (error.request) {
        const errorMessage = 'No response received from the server. Please try again.';
        setErrors({ general: errorMessage });
        toast({
          title: 'Network Error',
          description: errorMessage,
          variant: 'destructive',
          duration: 5000,
        });
      } else {
        const errorMessage = 'An error occurred while uploading. Please try again.';
        setErrors({ general: errorMessage });
        toast({
          title: 'Upload Error',
          description: errorMessage,
          variant: 'destructive',
          duration: 5000,
        });
      }
    } finally {
      setUploading(false);
      setLoadingText('');
      loadingTextIndex.current = 0;
    }
  };

  const handleAreaClick = () => {
    fileInputRef.current.click();
  };

  const handleClearAllImages = () => {
    setImages([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setErrors(prevErrors => ({
      ...prevErrors,
      imageLimit: null
    }));
  };

  const handleFileIconClick = (e) => {
    e.stopPropagation();
    fileInputRef.current.click();
  };

  const Loader = () => {
    return (
      <div className="w-full gap-x-2 flex justify-center items-center flex-col">
        <div className="flex gap-x-3">
          <div className="w-5 bg-[#d991c2] animate-pulse h-5 rounded-full animate-bounce" />
          <div className="w-5 animate-pulse h-5 bg-[#9869b8] rounded-full animate-bounce" />
          <div className="w-5 h-5 animate-pulse bg-[#6756cc] rounded-full animate-bounce" />
        </div>
        <div className="loading-text mt-2">{loadingText}</div>
      </div>
    );
  };

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8">
      <Joyride
        {...config}
        run={runTour}
        steps={steps}
        callback={handleJoyrideCallback}
      />

      <StyledWrapper>
        <div className="content-wrapper">
          {!uploading && (
            <div className="container upload-container">
              <div 
                className={`header ${isDragging ? 'dragging' : ''}`}
                onDragEnter={handleDragIn}
                onDragLeave={handleDragOut}
                onDragOver={handleDrag}
                onDrop={handleDrop}
                onClick={handleAreaClick}
              >
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10V9C7 6.23858 9.23858 4 12 4C14.7614 4 17 6.23858 17 9V10C19.2091 10 21 11.7909 21 14C21 15.4806 20.1956 16.8084 19 17.5M7 10C4.79086 10 3 11.7909 3 14C3 15.4806 3.8044 16.8084 5 17.5M7 10C7.43285 10 7.84965 10.0688 8.24006 10.1959M12 12V21M12 12L15 15M12 12L9 15" stroke="#000000" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p>{isDragging ? 'Drop files here' : 'Drag & Drop files here or click to browse. Max 10 images at a time'}</p>
              </div>
              <div className="footer">
                <svg 
                  fill="#000000" 
                  viewBox="0 0 32 32" 
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={handleFileIconClick}
                  className="clickable-icon"
                >
                  <path d="M15.331 6H8.5v20h15V14.154h-8.169z" />
                  <path d="M18.153 6h-.009v5.342H23.5v-.002z" />
                </svg>
                <p className="file-count">{images.length > 0 ? `${images.length} file(s) selected` : 'No file selected'}</p>
                <svg 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={handleClearAllImages}
                  className="trash-icon clickable-icon"
                >
                  <path d="M5.16565 10.1534C5.07629 8.99181 5.99473 8 7.15975 8H16.8402C18.0053 8 18.9237 8.9918 18.8344 10.1534L18.142 19.1534C18.0619 20.1954 17.193 21 16.1479 21H7.85206C6.80699 21 5.93811 20.1954 5.85795 19.1534L5.16565 10.1534Z" stroke="#000000" strokeWidth={2} />
                  <path d="M19.5 5H4.5" stroke="#000000" strokeWidth={2} strokeLinecap="round" />
                  <path d="M10 3C10 2.44772 10.4477 2 11 2H13C13.5523 2 14 2.44772 14 3V5H10V3Z" stroke="#000000" strokeWidth={2} />
                </svg>
              </div>
              <input
                id="file"
                type="file"
                onChange={handleImageChange}
                multiple
                ref={fileInputRef}
                style={{ display: 'none' }}
              />
              {errors.imageLimit && <p className="error">{errors.imageLimit}</p>}
              {images.length > 0 && (
                <div className="selected-files">
                  <p>{images.length} / 10 images selected</p>
                  {images.map((file, index) => (
                    <div key={index} className="file-item">
                      <span>{file.name}</span>
                      <button onClick={() => handleRemoveImage(index)} className="remove-file">
                        ✕
                      </button>
                    </div>
                  ))}
                </div>
              )}
              {errors.general && <p className="error">{errors.general}</p>}
              {errors.subscription && (
                <div className="subscription-error">
                  <p>{errors.subscription}</p>
                  <Button 
                    onClick={() => window.location.href = '/subscription'} 
                    className="upgrade-button"
                  >
                    Upgrade Plan
                  </Button>
                </div>
              )}
              <button
                onClick={handleSubmit}
                disabled={images.length === 0}
                className="upload-button"
              >
                Upload Outfits
              </button>
            </div>
          )}
          {uploading && (
            <div className="loading-container">
              <Loader />
            </div>
          )}
        </div>
      </StyledWrapper>
    </div>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
  }

  .container {
    height: 400px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 4px 4px 30px rgba(0, 0, 0, .2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    gap: 5px;
    background-color: rgba(0, 110, 255, 0.041);
  }

  .header {
    flex: 1;
    width: 100%;
    border: 2px dashed royalblue;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .header.dragging {
    background-color: rgba(0, 110, 255, 0.1);
    border: 2px solid royalblue;
  }

  .header svg {
    height: 100px;
  }

  .header p {
    text-align: center;
    color: black;
  }

  .footer {
    background-color: rgba(0, 110, 255, 0.075);
    width: 100%;
    height: 40px;
    padding: 8px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
    border: none;
  }

  .footer .clickable-icon {
    height: 100%;
    fill: royalblue;
    background-color: rgba(70, 66, 66, 0.103);
    border-radius: 50%;
    padding: 2px;
    cursor: pointer;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.205);
  }

  .footer .file-count {
    flex: 1;
    text-align: center;
    cursor: default;
    user-select: none;
  }

  #file {
    display: none;
  }

  .error {
    color: red;
    text-align: center;
    margin-top: 10px;
  }

  .progress-bar {
    width: 100%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
  }

  .progress {
    height: 100%;
    background-color: royalblue;
    transition: width 0.3s ease;
  }

  .upload-button {
    width: 100%;
    padding: 10px;
    background-color: royalblue;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-top: 10px;

    &:hover {
      background-color: #2a52be;
    }

    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }
  }

  .selected-files {
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    margin-top: 10px;
  }

  .selected-files p {
    margin-bottom: 5px;
    font-weight: bold;
  }

  .file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: #f0f0f0;
    margin-bottom: 5px;
    border-radius: 5px;
  }

  .remove-file {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
    font-weight: bold;
  }

  .loading-container {
    display: flex;
    flex-direction: center;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
    background-color: rgba(0, 110, 255, 0.041);
    border-radius: 10px;
    box-shadow: 4px 4px 30px rgba(0, 0, 0, .2);
  }

  .loading-text {
    margin-top: 20px;
    font-weight: bold;
    color: #6756cc;
  }

  .subscription-error {
    width: 100%;
    padding: 15px;
    background-color: #fff3f3;
    border: 1px solid #ffcdd2;
    border-radius: 5px;
    margin: 10px 0;
    text-align: center;

    p {
      color: #d32f2f;
      margin-bottom: 10px;
    }

    .upgrade-button {
      background-color: #2196f3;
      color: white;
      padding: 8px 16px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 500;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #1976d2;
      }
    }
  }
`;

export default OutfitUpload;
