import React, { useState, useEffect, useCallback, memo } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { client } from '../axiosClient';

const StatCard = memo(({ title, value, className }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className={`relative p-4 rounded-xl border-2 border-dashed ${className}`}
  >
    <h3 className="text-lg font-semibold mb-2">{title}</h3>
    <p className="text-3xl font-bold">{value}</p>
  </motion.div>
));

StatCard.displayName = 'StatCard';

const ActionButton = memo(({ to, icon, text }) => (
  <Link 
    to={to}
    className="flex flex-col items-center justify-center p-6 bg-gray-200 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105"
  >
    <div className="text-4xl mb-2">{icon}</div>
    <span className="text-sm font-medium text-gray-700">{text}</span>
  </Link>
));

ActionButton.displayName = 'ActionButton';

const OutfitCard = memo(({ combination }) => {
  // Memoized style calculation function
  const getItemStyle = useCallback((outfit, index, totalItems) => {
    const baseStyle = {
      position: 'absolute',
      transition: 'transform 0.2s, z-index 0.2s',
    };

    const category = outfit.category?.toLowerCase() || '';

    // Helper function for slight position variation
    const vary = (base, range = 5) => base + (Math.sin(index * 3.14) * range);

    // Calculate size based on category and total items
    let width, height, zIndex, left, top;

    switch (category) {
      case 'outerwear':
        width = 45;
        height = 55;
        zIndex = 20;
        left = vary(75);
        top = vary(25);
        break;
      case 'top':
        width = 45;
        height = 50;
        zIndex = 15;
        left = vary(25);
        top = vary(25);
        break;
      case 'bottom':
        width = 35;
        height = 50;
        zIndex = 10;
        left = vary(25);
        top = vary(70);
        break;
      case 'dress':
        width = 40;
        height = 60;
        zIndex = 20;
        left = vary(20);
        top = vary(65);
        break;
      case 'shoes':
        width = 35;
        height = 30;
        zIndex = 15;
        left = vary(50);
        top = vary(85);
        break;
      case 'bag':
        width = 35;
        height = 40;
        zIndex = 25;
        left = vary(80);
        top = vary(75);
        break;
      case 'hat':
        width = 30;
        height = 30;
        zIndex = 25;
        left = vary(50);
        top = vary(20);
        break;
      default:
        width = 30;
        height = 35;
        zIndex = 20;
        left = vary(50);
        top = vary(50);
    }

    // Adjust sizes based on total items
    const sizeMultiplier = totalItems <= 3 ? 2 : 
                          totalItems <= 4 ? 1.8 : 
                          totalItems <= 5 ? 1.6 : 1.4;
    
    width *= sizeMultiplier;
    height *= sizeMultiplier;

    // Add slight rotation for natural look
    const rotate = (Math.sin(index * 2.5) * 15);

    return {
      ...baseStyle,
      width: `${width}%`,
      height: `${height}%`,
      left: `${left}%`,
      top: `${top}%`,
      zIndex,
      transform: `translate(-50%, -50%) rotate(${rotate}deg)`,
    };
  }, []);

  return (
    <div 
      className="block bg-white rounded-lg shadow-md p-4 transition-transform duration-300 transform hover:shadow-xl hover:scale-105"
    >
      {/* Outfit collage */}
      <div className="relative h-[300px] bg-gray-50 rounded-lg overflow-hidden">
        {combination.items.map((outfit, index) => (
          <div
            key={outfit.id}
            style={getItemStyle(outfit, index, combination.items.length)}
            className="hover:z-50 hover:scale-105 transition-all duration-200"
          >
            <img
              src={outfit.image_url}
              alt={`${outfit.category || 'Outfit item'} ${index + 1}`}
              className="w-full h-full object-contain rounded-lg"
              loading="lazy"
            />
          </div>
        ))}
      </div>

      {/* Prompt and date */}
      <div className="mt-4 space-y-2">
        <p className="text-sm text-gray-700 line-clamp-2">{combination.prompt}</p>
        <p className="text-sm text-gray-500">
          {new Date(combination.created_at).toLocaleDateString()}
        </p>
      </div>
    </div>
  );
});

OutfitCard.displayName = 'OutfitCard';

const Dashboard = () => {
  const [recentCombinations, setRecentCombinations] = useState([]);
  const [statistics, setStatistics] = useState({
    total_clothes: 0,
    total_favorites: 0,
    closet_value: 0
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch both statistics and combinations in parallel
        const [statsResponse, combinationsResponse] = await Promise.all([
          client.get('/api/user/statistics/'),
          client.get('/api/combination-history/')
        ]);

        setStatistics(statsResponse.data);
        setRecentCombinations(combinationsResponse.data.slice(0, 4)); // Get only the 4 most recent combinations
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);
  };

  return (
    <div className="container mx-auto px-4 py-8 space-y-8">
      {/* Stats Section */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <StatCard 
          title="Total Clothes" 
          value={statistics.total_clothes}
          className="bg-pink-50 border-pink-200"
        />
        <StatCard 
          title="Favorites" 
          value={statistics.total_favorites}
          className="bg-purple-50 border-purple-200"
        />
        <StatCard 
          title="Closet Value" 
          value={formatCurrency(statistics.closet_value)}
          className="bg-blue-50 border-blue-200"
        />
      </div>

      {/* Action Buttons */}
      <div className="grid grid-cols-2 gap-4">
        <ActionButton
          to="/generate"
          icon={
            <svg className="w-10 h-10 text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
          }
          text="Generate Outfits"
        />
        <ActionButton
          to="/upload"
          icon={
            <svg className="w-10 h-10 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg>
          }
          text="Upload Clothes"
        />
      </div>

      {/* Recent Combinations Section */}
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Recently </h2>
          <Link 
            to="/generate" 
            className="text-sm text-blue-600 hover:text-blue-800 transition-colors"
          >
            Generate More →
          </Link>
        </div>
        
        {isLoading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {[...Array(4)].map((_, index) => (
              <div 
                key={index}
                className="bg-gray-100 rounded-lg h-[400px] animate-pulse"
              />
            ))}
          </div>
        ) : recentCombinations.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {recentCombinations.map((combination) => (
              <OutfitCard
                key={combination.id}
                combination={combination}
              />
            ))}
          </div>
        ) : (
          <div className="text-center py-12 bg-gray-50 rounded-lg">
            <p className="text-gray-500">No outfits generated yet</p>
            <Link 
              to="/generate"
              className="mt-4 inline-block text-blue-600 hover:text-blue-800"
            >
              Generate your first outfit →
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Dashboard); 