import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import { client } from '../axiosClient';
import { Button } from './ui/button';
import { Input } from './ui/input';
import AvatarEditor from 'react-avatar-editor';
import { useToast } from './ui/use-toast';
import Joyride from 'react-joyride';
import { useStartTour } from '../hooks/startTour';

// Memoized Modal Components
const AvatarUploadModal = memo(({ onClose, onSave, currentAvatar }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [scale, setScale] = useState(1);
  const [error, setError] = useState('');
  const editorRef = useRef(null);

  const handleFileChange = useCallback((e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(URL.createObjectURL(e.target.files[0]));
      setError('');
    }
  }, []);

  const handleSave = useCallback(() => {
    if (editorRef.current) {
      try {
        const canvas = editorRef.current.getImageScaledToCanvas();
        canvas.toBlob((blob) => {
          const formData = new FormData();
          formData.append('avatar', blob, 'avatar.png');
          onSave(formData);
        });
      } catch (err) {
        setError('Failed to process image. Please try another image.');
        console.error('Error processing image:', err);
      }
    }
  }, [onSave]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Edit Profile Picture</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="space-y-4">
          <Input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full"
          />

          {error && (
            <div className="text-red-500 text-sm">{error}</div>
          )}

          {selectedFile && (
            <div className="space-y-4">
              <div className="flex justify-center">
                <AvatarEditor
                  ref={editorRef}
                  image={selectedFile}
                  width={200}
                  height={200}
                  border={50}
                  borderRadius={100}
                  color={[255, 255, 255, 0.6]}
                  scale={scale}
                  rotate={0}
                />
              </div>
              
              <div className="space-y-2">
                <label className="block text-sm font-medium">Zoom</label>
                <input
                  type="range"
                  min="1"
                  max="2"
                  step="0.01"
                  value={scale}
                  onChange={(e) => setScale(parseFloat(e.target.value))}
                  className="w-full"
                />
              </div>

              <div className="flex justify-end space-x-2">
                <Button onClick={onClose} variant="outline">Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

AvatarUploadModal.displayName = 'AvatarUploadModal';

const OutfitModal = memo(({ combination, onClose }) => {
  if (!combination) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-4 border-b border-gray-200 flex justify-between items-center">
          <h3 className="text-lg font-semibold">Outfit Details</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="p-6">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {combination.outfits.map((outfit, index) => (
              <div key={index} className="aspect-square">
                <img
                  src={outfit.image_url}
                  alt={`Outfit item ${index + 1}`}
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

OutfitModal.displayName = 'OutfitModal';

const ConfirmationModal = memo(({ isOpen, onClose, onConfirm, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <div className="text-center">
          <h3 className="text-lg font-semibold mb-4">{message}</h3>
          <div className="flex justify-center space-x-4">
            <Button variant="outline" onClick={onClose}>Cancel</Button>
            <Button 
              variant="destructive" 
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              Remove
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});

ConfirmationModal.displayName = 'ConfirmationModal';

// Memoized OutfitCard Component
const OutfitCard = memo(({ combination, onUnlike, onClick }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleUnlikeClick = useCallback((e) => {
    e.stopPropagation();
    setShowConfirmation(true);
  }, []);

  // Memoized style calculation function
  const getItemStyle = useCallback((outfit, index, totalItems) => {
    const baseStyle = {
      position: 'absolute',
      transition: 'transform 0.2s, z-index 0.2s',
    };

    const category = outfit.category?.toLowerCase() || '';

    // Group items by category
    const tops = combination.outfits.filter(o => 
      (o.category?.toLowerCase() === 'top' || o.category?.toLowerCase() === 'outerwear')
    ).length;
    const bottoms = combination.outfits.filter(o => 
      o.category?.toLowerCase() === 'bottom' || o.category?.toLowerCase() === 'dress'
    ).length;
    const accessories = combination.outfits.filter(o => 
      o.category?.toLowerCase() === 'bag' || o.category?.toLowerCase() === 'hat' || 
      o.category?.toLowerCase() === 'shoes' || o.category?.toLowerCase() === 'other'
    ).length;

    // Calculate size based on category and total items
    let width, height, zIndex, left, top;

    // Helper function for slight position variation
    const vary = (base, range = 5) => base + (Math.sin(index * 3.14) * range);

    switch (category) {
      case 'outerwear':
        width = 45;
        height = 55;
        zIndex = 20;
        // Position in top area, slightly to the right
        left = vary(75);
        top = vary(25);
        break;
      case 'top':
        width = 45;
        height = 50;
        zIndex = 15;
        // Position in top area, slightly to the left
        if (totalItems <= 4) {
          left = vary(50);
          top = vary(25);
        } else {
          left = vary(25);
          top = vary(25);
        }
        break;
      case 'bottom':
        width = 35;
        height = 50;
        zIndex = 10;
        // Position in bottom area
        left = vary(25);
        top = vary(70);
        break;
      case 'dress':
        width = 40;
        height = 60;
        zIndex = 20;
        // Position in center-bottom area
        left = vary(20);
        top = vary(65);
        break;
      case 'shoes':
        width = 35;
        height = 30;
        zIndex = 15;
        // Position at the bottom
        left = vary(50);
        top = vary(85);
        break;
      case 'bag':
        width = 35;
        height = 40;
        zIndex = 25;
        // Position in middle-right area
        left = vary(80);
        top = vary(75);
        break;
      case 'hat':
        width = 30;
        height = 30;
        zIndex = 25;
        // Position in top-right area
        left = vary(50);
        top = vary(20);
        break;
      default:
        // For unknown categories, distribute in middle area
        width = 30;
        height = 35;
        zIndex = 20;
        left = vary(50);
        top = vary(50);
    }

    // Adjust sizes based on total items
    const sizeMultiplier = totalItems <= 3 ? 2 : 
                          totalItems <= 4 ? 1.8 : 
                          totalItems <= 5 ? 1.6 : 1.4;
    
    width *= sizeMultiplier;
    height *= sizeMultiplier;

    // Add slight rotation for natural look
    const rotate = (Math.sin(index * 2.5) * 15); // Rotation between -15 and 15 degrees

    return {
      ...baseStyle,
      width: `${width}%`,
      height: `${height}%`,
      left: `${left}%`,
      top: `${top}%`,
      zIndex,
      transform: `translate(-50%, -50%) rotate(${rotate}deg)`,
    };
  }, []);

  return (
    <div className="relative bg-white rounded-lg shadow-md p-4 hover:shadow-lg transition-shadow">
      {/* Unlike button */}
      <button
        onClick={handleUnlikeClick}
        className="absolute top-2 right-2 z-50 bg-white rounded-full p-1 shadow-md hover:bg-gray-100 transition-colors"
      >
        <svg
          className="w-6 h-6 text-red-500 fill-current"
          viewBox="0 0 24 24"
        >
          <path d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
        </svg>
      </button>

      {/* Confirmation Modal */}
      <ConfirmationModal
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={() => onUnlike(combination.id)}
        message="Remove from favorites?"
      />

      {/* Outfit collage */}
      <div 
        className="relative h-[300px] cursor-pointer bg-gray-50 rounded-lg overflow-hidden"
        onClick={onClick}
      >
        {combination.outfits.map((outfit, index) => (
          <div
            key={outfit.id}
            style={getItemStyle(outfit, index, combination.outfits.length)}
            className="hover:z-50 hover:scale-105 transition-all duration-200"
          >
            <img
              src={outfit.image_url}
              alt={`${outfit.category || 'Outfit item'} ${index + 1}`}
              className="w-full h-full object-contain rounded-lg"
            />
          </div>
        ))}
      </div>

      {/* Created date */}
      <div className="mt-4 text-sm text-gray-500">
        {new Date(combination.created_at).toLocaleDateString()}
      </div>
    </div>
  );
});

OutfitCard.displayName = 'OutfitCard';

const Profile = memo(({ user, onUserUpdate }) => {
  const { toast } = useToast();
  const { runTour, steps, startTour, config } = useStartTour('profile');
  const [isEditing, setIsEditing] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isEditingAvatar, setIsEditingAvatar] = useState(false);
  const [profileData, setProfileData] = useState({
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    avatar: user.avatar || null,
  });
  const [passwordData, setPasswordData] = useState({
    old_password: '',
    new_password: '',
    confirm_password: '',
  });
  const [likedOutfits, setLikedOutfits] = useState([]);
  const [isLoadingLikedOutfits, setIsLoadingLikedOutfits] = useState(false);
  const [selectedCombination, setSelectedCombination] = useState(null);

  // Memoized handlers
  const fetchLikedOutfits = useCallback(async () => {
    setIsLoadingLikedOutfits(true);
    try {
      const response = await client.get('/api/favorite-outfits/');
      setLikedOutfits(response.data);
    } catch (err) {
      console.error('Failed to fetch liked outfits:', err);
      toast({
        title: "Error",
        description: "Failed to fetch liked outfits",
        variant: "destructive",
      });
    } finally {
      setIsLoadingLikedOutfits(false);
    }
  }, [toast]);

  const handleUnlike = useCallback(async (combinationId) => {
    try {
      await client.post(`/api/add-remove-favorite-outfit/${combinationId}/`);
      fetchLikedOutfits();
      toast({
        title: "Success",
        description: "Outfit removed from favorites",
      });
    } catch (err) {
      toast({
        title: "Error",
        description: "Failed to unlike outfit",
        variant: "destructive",
      });
    }
  }, [fetchLikedOutfits, toast]);

  const handleProfileUpdate = useCallback(async (e) => {
    e.preventDefault();
    try {
      const response = await client.patch('/api/profile/update/', profileData);
      toast({
        title: "Success",
        description: "Profile updated successfully",
      });
      onUserUpdate(response.data);
      setIsEditing(false);
    } catch (err) {
      toast({
        title: "Error",
        description: err.response?.data?.error || 'Failed to update profile',
        variant: "destructive",
      });
    }
  }, [profileData, onUserUpdate, toast]);

  const handlePasswordChange = useCallback(async (e) => {
    e.preventDefault();

    if (passwordData.new_password !== passwordData.confirm_password) {
      toast({
        title: "Error",
        description: "New passwords do not match",
        variant: "destructive",
      });
      return;
    }

    try {
      await client.post('/api/profile/change-password/', passwordData);
      toast({
        title: "Success",
        description: "Password changed successfully",
      });
      setIsChangingPassword(false);
      setPasswordData({
        old_password: '',
        new_password: '',
        confirm_password: '',
      });
    } catch (err) {
      toast({
        title: "Error",
        description: err.response?.data?.error || 'Failed to change password',
        variant: "destructive",
      });
    }
  }, [passwordData, toast]);

  const handleAvatarUpdate = useCallback(async (formData) => {
    try {
      const response = await client.patch('/api/profile/update-avatar/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const newAvatar = response.data.avatar;
      setProfileData(prev => ({ ...prev, avatar: newAvatar }));
      onUserUpdate({ ...user, avatar: newAvatar });
      setIsEditingAvatar(false);
      toast({
        title: "Success",
        description: "Profile picture updated successfully",
      });
    } catch (err) {
      toast({
        title: "Error",
        description: err.response?.data?.error || 'Failed to update profile picture',
        variant: "destructive",
      });
    }
  }, [user, onUserUpdate, toast]);

  const handleJoyrideCallback = useCallback((data) => {
    const { status } = data;
    if (status === 'finished' || status === 'skipped') {
      localStorage.setItem('hasSeenProfileTour', 'true');
    }
  }, []);

  // Effects
  useEffect(() => {
    fetchLikedOutfits();
  }, [fetchLikedOutfits]);

  useEffect(() => {
    const hasSeenTour = localStorage.getItem('hasSeenProfileTour');
    if (!hasSeenTour) {
      startTour();
      localStorage.setItem('hasSeenProfileTour', 'true');
    }
  }, [startTour]);

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8">
      <Joyride
        {...config}
        run={runTour}
        steps={steps}
        callback={handleJoyrideCallback}
      />
      
      {/* Profile Header */}
      <div className="flex items-center space-x-6">
        <div className="relative group profile-avatar">
          <div className="w-24 h-24 rounded-full overflow-hidden group-hover:opacity-75 transition-opacity duration-200">
            {profileData.avatar ? (
              <img
                src={profileData.avatar}
                alt="Profile"
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full bg-gradient-to-r from-purple-400 to-pink-500 flex items-center justify-center text-white text-2xl font-bold">
                {user.first_name ? user.first_name[0] : user.email[0]}
              </div>
            )}
          </div>
          <button
            onClick={() => setIsEditingAvatar(true)}
            className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-50 transition-all duration-200 rounded-full"
          >
            <svg
              className="w-8 h-8 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </button>
        </div>
        <div>
          <h1 className="text-2xl font-bold">
            {user.first_name ? `${user.first_name} ${user.last_name}` : 'User Profile'}
          </h1>
          <p className="text-gray-600">{user.email}</p>
          <p className="text-sm mt-1 text-purple-600 font-medium">
            {user.subscription_plan || 'Free Plan'}
          </p>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex flex-wrap gap-4">
        <a 
          href="https://stylegenai.lemonsqueezy.com/buy/bf6ddc46-347b-4c3a-9dac-21a9744411d7"
          target="_blank"
          rel="noopener noreferrer"
          className="subscription-button inline-flex items-center justify-center px-6 py-2 border-0 rounded-full text-sm font-medium text-white bg-gradient-to-l from-purple-500 to-pink-500 hover:from-pink-500 hover:to-purple-500 transition-all duration-200 shadow-lg"
        >
          Manage Subscription
        </a>
        <Button 
          onClick={() => setIsEditing(!isEditing)} 
          className={`edit-profile-button ${isEditing ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-700'} text-white transition-colors duration-200 rounded-full`}
        >
          {isEditing ? 'Cancel Edit' : 'Edit Profile'}
        </Button>
        <Button 
          onClick={() => setIsChangingPassword(!isChangingPassword)}
          className={`change-password-button ${isChangingPassword ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-700'} text-white transition-colors duration-200 rounded-full`}
        >
          {isChangingPassword ? 'Cancel' : 'Change Password'}
        </Button>
      </div>

      {/* Edit Profile Form */}
      {isEditing && (
        <form onSubmit={handleProfileUpdate} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">First Name</label>
            <Input
              type="text"
              value={profileData.first_name}
              onChange={(e) => setProfileData({...profileData, first_name: e.target.value})}
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Last Name</label>
            <Input
              type="text"
              value={profileData.last_name}
              onChange={(e) => setProfileData({...profileData, last_name: e.target.value})}
            />
          </div>
          <Button type="submit">Save Changes</Button>
        </form>
      )}

      {/* Change Password Form */}
      {isChangingPassword && (
        <form onSubmit={handlePasswordChange} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Current Password</label>
            <Input
              type="password"
              value={passwordData.old_password}
              onChange={(e) => setPasswordData({...passwordData, old_password: e.target.value})}
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">New Password</label>
            <Input
              type="password"
              value={passwordData.new_password}
              onChange={(e) => setPasswordData({...passwordData, new_password: e.target.value})}
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Confirm New Password</label>
            <Input
              type="password"
              value={passwordData.confirm_password}
              onChange={(e) => setPasswordData({...passwordData, confirm_password: e.target.value})}
            />
          </div>
          <Button type="submit">Update Password</Button>
        </form>
      )}

      {/* Liked Outfits Card */}
      <div className="favorites-section bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold">Favorite Outfits</h2>
        </div>
        <div className="p-4">
          {isLoadingLikedOutfits ? (
            <div className="flex justify-center items-center h-32">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
            </div>
          ) : likedOutfits.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {likedOutfits.map((combination) => (
                <OutfitCard
                  key={combination.id}
                  combination={combination}
                  onUnlike={handleUnlike}
                  onClick={() => setSelectedCombination(combination)}
                />
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-500 py-8">
              No liked outfits yet
            </div>
          )}
        </div>
      </div>

      {/* Modal for viewing outfit details */}
      {selectedCombination && (
        <OutfitModal
          combination={selectedCombination}
          onClose={() => setSelectedCombination(null)}
        />
      )}

      {/* Avatar Upload Modal */}
      {isEditingAvatar && (
        <AvatarUploadModal
          onClose={() => setIsEditingAvatar(false)}
          onSave={handleAvatarUpdate}
          currentAvatar={profileData.avatar}
        />
      )}
    </div>
  );
});

Profile.displayName = 'Profile';

export default Profile;