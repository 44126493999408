import { useState, useEffect } from 'react';
import { tourSteps } from '../lib/joyRide';

export const useStartTour = (pageName) => {
    const [runTour, setRunTour] = useState(false);
    const [steps, setSteps] = useState([]);
    
    // Consolidated Joyride configurations
    const [config] = useState({
        // Core options
        continuous: true,
        hideCloseButton: true,
        showProgress: true,
        showSkipButton: true,
        disableOverlayClose: false,
        disableScrolling: true,
        scrollOffset: 200,
        spotlightClicks: true,

        // Styles configuration
        styles: {
            options: {
                beaconSize: 36,
                overlayColor: 'rgba(0, 0, 0, 0.5)',
                backgroundColor: '#fff',
                primaryColor: '#800080',
                textColor: '#2c3e50',
                zIndex: 9999,
            },
            tooltipContainer: {
                textAlign: 'left',
            },
            buttonNext: {
                backgroundColor: '#0000FF',
                fontSize: 13,
            },
            buttonBack: {
                color: '#000000',
                marginRight: 10,
                fontSize: 13,
            },
            buttonSkip: {
                color: '#FF0000',
                backgroundColor: '#fff',
                fontSize: 13,
            }
        },

        // Floater configuration
        floaterProps: {
            disableAnimation: true,
            placement: 'center',
            offset: 20
        },

        // Spotlight configuration
        spotlightPadding: 8
    });

    useEffect(() => {
        // Get the steps for the current page
        const pageSteps = tourSteps[pageName];
        if (pageSteps) {
            // Convert the object steps into an array format that react-joyride expects
            const formattedSteps = Object.entries(pageSteps).map(([key, step]) => ({
                ...step,
                placement: step.placement || 'center',
                disableBeacon: step.disableBeacon || false
            }));
            setSteps(formattedSteps);
        }
    }, [pageName]);

    const startTour = () => {
        setRunTour(true);
    };

    const endTour = () => {
        setRunTour(false);
    };

    return {
        runTour,
        steps,
        startTour,
        endTour,
        setRunTour,
        config  // Export the consolidated configuration
    };
};
    