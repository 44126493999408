import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';



// Individual pricing card component
const PricingCard = ({ title, price, savings, features, isYearly, store_url, isPopular }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  return (
    <motion.div
      ref={ref}
      variants={cardVariants}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      className={`w-full bg-white rounded-2xl shadow-xl overflow-hidden ${isPopular ? 'transform hover:scale-105' : 'hover:scale-102'} transition-all duration-300`}
    >
      {isPopular && (
        <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white text-center py-2 text-sm font-semibold">
          Best Offer
        </div>
      )}
      
      <div className="p-8">
        <h3 className="text-2xl font-bold text-gray-900 mb-4">{title}</h3>
        <div className="flex items-baseline mb-2">
            <span className="text-4xl font-extrabold text-gray-900">${price}</span>
            <span className="text-gray-600 ml-1">/{isYearly ? 'year' : 'month'}</span>
            {isYearly && (
                <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-green-800">
                    Billing annually
                </span>
            )}
        </div>
        <div className="flex items-center mb-8">
          <span className="text-green-600 ml-1">{savings}</span>
        </div>

        <ul className="space-y-4 mb-8">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center">
              <svg className="w-5 h-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span className="text-gray-600">{feature}</span>
            </li>
          ))}
        </ul>

        <a
          href={store_url}
          target="_blank"
          rel="noopener noreferrer"
          className={`block w-full text-center py-3 px-6 rounded-full text-white font-semibold transition-all duration-300 ${
            isPopular
              ? 'bg-gradient-to-r from-blue-500 to-purple-600 hover:from-purple-500 hover:to-blue-600'
              : 'bg-gray-900 hover:bg-gray-800'
          }`}
        >
          Get Started
        </a>
      </div>
    </motion.div>
  );
};

// FAQ Component
const FAQ = ({question, answer}) => {
return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
    <h4 className="font-semibold mb-2">{question}</h4>
    <p className="text-gray-600">{answer}</p>
    </div>
);
}

const Pricing = () => {
  const [isYearly, setIsYearly] = useState(false);
  const pricingRef = useRef(null);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const headerVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  const calculateYearlySavings = (monthlyPrice) => {
    const yearlyPrice = monthlyPrice * 10; // 20% discount
    const monthlySavings = monthlyPrice * 12 - yearlyPrice;
    return Math.round(monthlySavings);
  };

  return (
    <section id="pricing" ref={pricingRef} className="py-24 relative overflow-hidden">
      {/* Background Elements */}
      <div className="absolute inset-0 bg-gradient-to-b from-gray-50 to-white"></div>
      <div 
        className="absolute inset-0 opacity-[0.03]"
        style={{
          backgroundImage: `radial-gradient(#000 1px, transparent 1px)`,
          backgroundSize: '32px 32px'
        }}
      ></div>
      
      {/* Blob Decorations */}
      <div className="absolute top-0 left-0 w-72 h-72 bg-purple-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
      <div className="absolute top-0 right-0 w-72 h-72 bg-yellow-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
      <div className="absolute -bottom-8 left-20 w-72 h-72 bg-pink-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>

      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <motion.div
          ref={ref}
          variants={headerVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          className="mb-12 text-center"
        >
          <h2 className="text-base font-semibold leading-7 text-blue-600">Pricing</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Choose your perfect plan
          </p>
          <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600">
            Simple and transparent pricing
          </p>

          {/* Billing toggle */}
          <div className="flex justify-center items-center mt-8">
            <span className={`mr-3 ${isYearly ? 'text-gray-500' : 'text-gray-900 font-semibold'}`}>Monthly</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input 
                type="checkbox" 
                className="sr-only peer" 
                checked={isYearly} 
                onChange={() => setIsYearly(!isYearly)} 
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
            <span className={`ml-3 flex items-center gap-2 ${isYearly ? 'text-gray-900 font-semibold' : 'text-gray-500'}`}>
              Yearly
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                  Save ~20%
                </span>
            </span>
          </div>
        </motion.div>

        {/* Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Eco Plan */}
          <div className="flex">
            <PricingCard
              title="Eco Plan"
              price={isYearly ? 80 : 8}
              savings={isYearly ? `Saving $${calculateYearlySavings(8)} annually` : null}
              isYearly={isYearly}
              features={[
                "Limited image uploads up to 30",
                "Unlimited suggestions",
                "Basic support",
                "Access to core features",
              ].filter(Boolean)}
              store_url="https://stylegenai.lemonsqueezy.com/buy/aae1469c-444d-449b-b2a5-f9ecc5886625?enabled=566980%2C567067"
            />
          </div>

          {/* Luxury Plan */}
          <div className="flex lg:col-span-1">
            <PricingCard
            title="Luxury Plan"
            price={isYearly ? 150 : 15}
            savings={isYearly ? `Saving $${calculateYearlySavings(15)} annually` : null}
            isYearly={isYearly}
            features={[
                "Unlimited image uploads",
                "Unlimited suggestions",
                "Priority support",
                "Early access to new features",
                "Advanced analytics",
                "Custom categories",
            ].filter(Boolean)}
            store_url="https://stylegenai.lemonsqueezy.com/buy/10c5f581-7abd-402f-8c66-7f9973ba67b6?enabled=567066%2C567068"
            isPopular={true}
            />
          </div>

          {/* Elite Plan */}
          <div className="flex md:col-start-1 lg:col-start-auto">
            <PricingCard
              title="Elite Plan"
              price={isYearly ? 120 : 12}
              savings={isYearly ? `Saving $${calculateYearlySavings(12)} annually` : null}
              isYearly={isYearly}
              features={[
                "Limited image uploads up to 50",
                "Unlimited suggestions",
                "Priority support",
                "Access to core features",
                "Basic analytics",
              ].filter(Boolean)}
              store_url="https://stylegenai.lemonsqueezy.com/buy/4cf4a392-c6b0-4bd7-9260-f7fd86dac435?enabled=567071%2C567072"
            />
          </div>
        </div>

        {/* FAQ Section */}
        <div className="mt-20">
          <h3 className="text-2xl font-bold text-center mb-8">Frequently Asked Questions</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <FAQ 
                question="Can I change plans later?" 
                answer="Yes, you can upgrade or downgrade your plan at any time. The changes will be reflected in your next billing cycle." 
            />
            <FAQ 
                question="What payment methods do you accept?" 
                answer="We accept all major credit cards and PayPal via Lemon Squeezy." 
            />
            <FAQ 
                question="Is there a free trial?" 
                answer="While we don’t currently offer a free trial, we’ve designed our plans to provide exceptional value and ensure you get the most out of your investment." 
            />
            <FAQ 
                question="Can I cancel anytime?" 
                answer="Yes, you can cancel your subscription at any time. No questions asked." 
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;