import './App.css';
import './tailwind.css';
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { client, refreshToken } from './axiosClient';
import { Toaster } from './components/ui/toaster';

import Layout from './components/Layout';
import OutfitUpload from './components/OutfitUpload';
import OutfitGallery from './components/OutfitGallery copy';
import OutfitSuggestion from './components/OutfitSuggestion';
import LoginRegister from './components/LoginRegister';
import Profile from './components/Profile';
import ForgotPassword from './components/ForgotPassword';
import ResetPasswordConfirm from './components/ResetPasswordConfirm';
import Dashboard from './components/Dashboard';
import Favorites from './components/Favorites';
import OutfitDetail from './components/OutfitDetail';
import Feedback from './components/Feedback';
import Pricing from './components/Pricing';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [refreshGallery, setRefreshGallery] = useState(0);
  const [loading, setLoading] = useState(true);
  const [userStats, setUserStats] = useState({
    totalClothes: 0,
    favorites: 0,
    closetValue: 0
  });

  const triggerGalleryRefresh = useCallback(() => {
    setRefreshGallery(prev => prev + 1);
  }, []);

  const handleUserUpdate = useCallback((updatedUser) => {
    setCurrentUser(updatedUser);
  }, []);

  const fetchUserStats = useCallback(async () => {
    try {
      const response = await client.get('/api/user/stats/');
      setUserStats({
        totalClothes: response.data.total_clothes || 0,
        favorites: response.data.favorites || 0,
        closetValue: response.data.closet_value || 0
      });
    } catch (error) {
      console.error('Error fetching user stats:', error);
    }
  }, []);

  const checkAuthStatus = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('access_token') || localStorage.getItem('access_token');
    const refreshToken = urlParams.get('refresh_token') || localStorage.getItem('refresh_token');

    if (accessToken && refreshToken) {
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('refresh_token', refreshToken);
      client.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      // Remove the tokens from the URL
      window.history.replaceState({}, document.title, window.location.pathname);

      try {
        const response = await client.get("/api/user/");
        setCurrentUser(response.data.user);
        await fetchUserStats();
      } catch (error) {
        console.error("Error fetching user data:", error);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
      }
    }
    setLoading(false);
  }, [fetchUserStats]);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  useEffect(() => {
    if (currentUser) {
      fetchUserStats();
    }
  }, [currentUser, fetchUserStats]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleLogout = () => {
    // Clear auth tokens only
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    delete client.defaults.headers.common['Authorization'];
    setCurrentUser(null);
  };

  return (
    <Router>
      <Layout 
        isLoggedIn={!!currentUser} 
        onLogout={handleLogout}
        username={currentUser?.first_name || currentUser?.email}
        avatar={currentUser?.avatar}
      >
        <Routes>
          {currentUser ? (
            // Protected routes
            <>
              <Route path="/" element={<Dashboard userStats={userStats} />} />
              <Route path="/profile" element={
                <Profile 
                  user={currentUser} 
                  onUserUpdate={handleUserUpdate}
                />
              } />
              <Route path="/upload" element={
                <OutfitUpload onUploadSuccess={triggerGalleryRefresh} />
              } />
              <Route path="/my-closet" element={
                <OutfitGallery refreshTrigger={refreshGallery} />
              } />
              <Route path="/generate" element={<OutfitSuggestion />} />
              <Route path="/favorites" element={<Favorites />} />
              <Route path="/my-closet/:id" element={<OutfitDetail />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/login" element={<Navigate to="/" />} />
            </>
          ) : (
            // Public routes
            <>
              <Route path="/login" element={<LoginRegister setCurrentUser={setCurrentUser} />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:uid/:token" element={<ResetPasswordConfirm />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
          <Route path="/pricing" element={<Pricing />} />
        </Routes>
      </Layout>
      <Toaster />
    </Router>
  );
}

export default App;
