import React, { useState, useEffect, useCallback, memo, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, Transition } from '@headlessui/react';
import { client } from '../axiosClient';
import { useToast } from './ui/use-toast';
import { Button } from './ui/button';
import { XMarkIcon, AdjustmentsHorizontalIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog";

const CategoryFilter = memo(({ categories, selectedCategory, onSelect }) => (
  <div className="flex flex-wrap gap-2 mb-6">
    {categories.map((category) => (
      <button
        key={category}
        onClick={() => onSelect(category)}
        className={`px-4 py-2 rounded-full border-2 transition-all duration-200 ${
          selectedCategory === category
            ? 'border-purple-600 bg-purple-600 text-white'
            : 'border-gray-300 hover:border-purple-600'
        }`}
      >
        {category}
      </button>
    ))}
  </div>
));

CategoryFilter.displayName = 'CategoryFilter';

const GridSizeSelector = memo(({ gridSize, onGridSizeChange }) => {
  const sizes = [2, 3, 4, 6];
  
  return (
    <div className="flex items-center gap-2">
      <span className="text-sm font-medium text-gray-700">Grid Size:</span>
      <select
        value={gridSize}
        onChange={(e) => onGridSizeChange(Number(e.target.value))}
        className="rounded-md border border-gray-300 px-3 py-1.5 text-sm"
      >
        {sizes.map(size => (
          <option key={size} value={size}>
            {size}x{size}
          </option>
        ))}
      </select>
    </div>
  );
});

const FilterButton = memo(({ colors, seasons, activities, brands, selectedColor, selectedSeason, selectedActivity, selectedBrand, onFilterChange }) => {
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [submenuPosition, setSubmenuPosition] = useState('right');
  const menuRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (menuRef.current) {
        const rect = menuRef.current.getBoundingClientRect();
        const spaceOnRight = window.innerWidth - rect.right;
        const spaceOnLeft = rect.left;
        
        setSubmenuPosition(spaceOnRight > 200 ? 'right' : spaceOnLeft > 200 ? 'left' : 'bottom');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMenuItemClick = (e, menuType) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveSubmenu(menuType);
  };

  return (
    <Menu as="div" className="relative" ref={menuRef}>
      {({ open }) => (
        <>
          <Menu.Button className="flex items-center gap-2 px-3 py-1.5 sm:px-4 sm:py-2 rounded-full border border-gray-300 hover:bg-gray-50 text-sm sm:text-base">
            <AdjustmentsHorizontalIcon className="h-4 w-4 sm:h-5 sm:w-5" />
        <span>Filter</span>
      </Menu.Button>
      <Transition
            show={open}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-in"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
            <Menu.Items static className="absolute mt-2 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50 w-48 sm:w-56">
              <div className="py-1">
                {/* Main menu items */}
                <div>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active || activeSubmenu === 'color' ? 'bg-gray-100' : ''
                        } flex w-full items-center justify-between px-3 py-1.5 sm:px-4 sm:py-2 text-sm text-gray-700`}
                        onMouseEnter={() => setActiveSubmenu('color')}
                        onClick={(e) => handleMenuItemClick(e, 'color')}
                      >
                        <span>Color</span>
                        <svg className={`h-4 w-4 transform ${submenuPosition === 'bottom' ? 'rotate-90' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active || activeSubmenu === 'season' ? 'bg-gray-100' : ''
                        } flex w-full items-center justify-between px-3 py-1.5 sm:px-4 sm:py-2 text-sm text-gray-700`}
                        onMouseEnter={() => setActiveSubmenu('season')}
                        onClick={(e) => handleMenuItemClick(e, 'season')}
                      >
                        <span>Season</span>
                        <svg className={`h-4 w-4 transform ${submenuPosition === 'bottom' ? 'rotate-90' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active || activeSubmenu === 'activity' ? 'bg-gray-100' : ''
                        } flex w-full items-center justify-between px-3 py-1.5 sm:px-4 sm:py-2 text-sm text-gray-700`}
                        onMouseEnter={() => setActiveSubmenu('activity')}
                        onClick={(e) => handleMenuItemClick(e, 'activity')}
                      >
                        <span>Activity</span>
                        <svg className={`h-4 w-4 transform ${submenuPosition === 'bottom' ? 'rotate-90' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active || activeSubmenu === 'brand' ? 'bg-gray-100' : ''
                        } flex w-full items-center justify-between px-3 py-1.5 sm:px-4 sm:py-2 text-sm text-gray-700`}
                        onMouseEnter={() => setActiveSubmenu('brand')}
                        onClick={(e) => handleMenuItemClick(e, 'brand')}
                      >
                        <span>Brand</span>
                        <svg className={`h-4 w-4 transform ${submenuPosition === 'bottom' ? 'rotate-90' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                      </button>
                    )}
                  </Menu.Item>
                </div>

                {/* Submenu with dynamic positioning */}
                {activeSubmenu && (
                  <div 
                    className={`
                      ${submenuPosition === 'right' ? 'absolute left-full top-0 ml-1' : 
                        submenuPosition === 'left' ? 'absolute right-full top-0 mr-1' : 
                        'relative mt-1 ml-4'
                      }
                      w-48 sm:w-56 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5
                    `}
                    onMouseLeave={() => submenuPosition !== 'bottom' && setActiveSubmenu(null)}
                  >
                    <div className="py-1 max-h-48 sm:max-h-64 overflow-y-auto">
                      {activeSubmenu === 'color' && colors.map((color) => (
                        <Menu.Item key={color}>
                          {({ active }) => (
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onFilterChange('color', color);
                                setActiveSubmenu(null);
                              }}
                              className={`${
                                active ? 'bg-gray-100' : ''
                              } ${
                                selectedColor === color ? 'text-purple-600 font-medium' : 'text-gray-700'
                              } group flex w-full items-center px-3 py-1.5 sm:px-4 sm:py-2 text-sm`}
                            >
                              {color}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                      {activeSubmenu === 'season' && seasons.map((season) => (
                        <Menu.Item key={season}>
                          {({ active }) => (
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onFilterChange('season', season);
                                setActiveSubmenu(null);
                              }}
                              className={`${
                                active ? 'bg-gray-100' : ''
                              } ${
                                selectedSeason === season ? 'text-purple-600 font-medium' : 'text-gray-700'
                              } group flex w-full items-center px-3 py-1.5 sm:px-4 sm:py-2 text-sm`}
                            >
                              {season}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                      {activeSubmenu === 'activity' && activities.map((activity) => (
                        <Menu.Item key={activity}>
                          {({ active }) => (
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onFilterChange('activity', activity);
                                setActiveSubmenu(null);
                              }}
                              className={`${
                                active ? 'bg-gray-100' : ''
                              } ${
                                selectedActivity === activity ? 'text-purple-600 font-medium' : 'text-gray-700'
                              } group flex w-full items-center px-3 py-1.5 sm:px-4 sm:py-2 text-sm`}
                            >
                              {activity}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                      {activeSubmenu === 'brand' && brands.map((brand) => (
                        <Menu.Item key={brand}>
                          {({ active }) => (
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onFilterChange('brand', brand);
                                setActiveSubmenu(null);
                              }}
                              className={`${
                                active ? 'bg-gray-100' : ''
                              } ${
                                selectedBrand === brand ? 'text-purple-600 font-medium' : 'text-gray-700'
                              } group flex w-full items-center px-3 py-1.5 sm:px-4 sm:py-2 text-sm`}
                            >
                              {brand}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </div>
                )}
              </div>
        </Menu.Items>
      </Transition>
        </>
      )}
    </Menu>
  );
});

const ActiveFilters = memo(({ selectedColor, selectedSeason, selectedActivity, selectedBrand, onRemove }) => {
  const filters = [
    { type: 'color', value: selectedColor },
    { type: 'season', value: selectedSeason },
    { type: 'activity', value: selectedActivity },
    { type: 'brand', value: selectedBrand }
  ].filter(filter => filter.value !== 'All');

  if (filters.length === 0) return null;

  return (
    <div className="flex flex-wrap gap-2 mb-4">
      {filters.map(({ type, value }) => (
        <button
          key={`${type}-${value}`}
          onClick={() => onRemove(type)}
          className="inline-flex items-center gap-1 px-3 py-1 bg-purple-50 text-purple-700 rounded-full text-sm hover:bg-purple-100 transition-colors"
        >
          <span className="capitalize">{value}</span>
          <XCircleIcon className="h-4 w-4" />
        </button>
      ))}
    </div>
  );
});

ActiveFilters.displayName = 'ActiveFilters';

const OutfitModal = memo(({ outfit, onClose, onEdit, onDelete }) => {
  const [generatedOutfits, setGeneratedOutfits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const { toast } = useToast();

  const fetchGeneratedOutfits = useCallback(async () => {
    try {
      // This endpoint needs to be implemented in the backend
      const response = await client.get(`/api/outfits/${outfit.id}/generated-outfits/`);
      setGeneratedOutfits(response.data);
    } catch (error) {
      console.error('Error fetching generated outfits:', error);
      toast({
        title: "Error",
        description: "Failed to load generated outfits",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  }, [outfit.id, toast]);

  useEffect(() => {
    fetchGeneratedOutfits();
  }, [fetchGeneratedOutfits]);

  return (
    <Dialog open={true} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-4xl w-full bg-white rounded-xl shadow-xl">
          <div className="relative p-6">
            <button
              onClick={onClose}
              className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>

            <div className="space-y-6">
              {/* Selected Item */}
              <div className="w-48 h-48 mx-auto">
                <img
                  src={outfit.image}
                  alt={outfit.description || 'Outfit item'}
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>

              {/* Action Buttons */}
              <div className="flex justify-center gap-4">
                <Button onClick={() => setShowDetails(true)}>See Details</Button>
                <Button onClick={onEdit}>Edit</Button>
                <Button variant="destructive" onClick={onDelete}>Delete</Button>
              </div>

              {/* Generated Outfits */}
              <div>
                <h3 className="text-lg font-semibold mb-4">Generated Outfits with this item</h3>
                {loading ? (
                  <div className="text-center py-8">Loading...</div>
                ) : (
                  <div className="grid grid-cols-3 gap-4">
                    {generatedOutfits.map((genOutfit, index) => (
                      <div key={index} className="aspect-square bg-gray-100 rounded-lg p-4">
                        {/* Display generated outfit */}
                        {genOutfit.outfits.map((item, i) => (
                          <img
                            key={i}
                            src={item.image_url}
                            alt={`Outfit item ${i + 1}`}
                            className="w-full h-full object-cover"
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
});

const OutfitGallery = ({ refreshTrigger }) => {
  const navigate = useNavigate();
  const [outfits, setOutfits] = useState([]);
  const [filteredOutfits, setFilteredOutfits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [gridSize, setGridSize] = useState(4);
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  
  // Filter states
  const [categories, setCategories] = useState(['All']);
  const [colors, setColors] = useState(['All']);
  const [seasons, setSeasons] = useState(['All']);
  const [activities, setActivities] = useState(['All']);
  const [brands, setBrands] = useState(['All']);
  
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedColor, setSelectedColor] = useState('All');
  const [selectedSeason, setSelectedSeason] = useState('All');
  const [selectedActivity, setSelectedActivity] = useState('All');
  const [selectedBrand, setSelectedBrand] = useState('All');
  
  const { toast } = useToast();

  const fetchOutfits = useCallback(async () => {
    try {
      const response = await client.get('/api/outfits/');
      setOutfits(response.data);
      
      // Extract unique filter options
      const categorySet = new Set(['All', ...response.data.map(outfit => outfit.category?.name).filter(Boolean)]);
      const colorSet = new Set(['All', ...response.data.map(outfit => outfit.color?.name).filter(Boolean)]);
      const seasonSet = new Set(['All', ...response.data.flatMap(outfit => outfit.season.map(s => s.name))]);
      const activitySet = new Set(['All', ...response.data.flatMap(outfit => outfit.activity.map(a => a.name))]);
      const brandSet = new Set(['All', ...response.data.map(outfit => outfit.brand?.name).filter(Boolean)]);
      
      setCategories(Array.from(categorySet));
      setColors(Array.from(colorSet));
      setSeasons(Array.from(seasonSet));
      setActivities(Array.from(activitySet));
      setBrands(Array.from(brandSet));
      
      setFilteredOutfits(response.data);
    } catch (error) {
      console.error('Error fetching outfits:', error);
      toast({
        title: "Error",
        description: "Failed to load outfits",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);

  const filterOutfits = useCallback(() => {
    let filtered = outfits;
    
    if (selectedCategory !== 'All') {
      filtered = filtered.filter(outfit => outfit.category?.name === selectedCategory);
    }
    if (selectedColor !== 'All') {
      filtered = filtered.filter(outfit => outfit.color?.name === selectedColor);
    }
    if (selectedSeason !== 'All') {
      filtered = filtered.filter(outfit => outfit.season.some(s => s.name === selectedSeason));
    }
    if (selectedActivity !== 'All') {
      filtered = filtered.filter(outfit => outfit.activity.some(a => a.name === selectedActivity));
    }
    if (selectedBrand !== 'All') {
      filtered = filtered.filter(outfit => outfit.brand?.name === selectedBrand);
    }
    
    setFilteredOutfits(filtered);
  }, [outfits, selectedCategory, selectedColor, selectedSeason, selectedActivity, selectedBrand]);

  const handleFilterChange = useCallback((type, value) => {
    switch (type) {
      case 'color':
        setSelectedColor(value);
        break;
      case 'season':
        setSelectedSeason(value);
        break;
      case 'activity':
        setSelectedActivity(value);
        break;
      case 'brand':
        setSelectedBrand(value);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    fetchOutfits();
  }, [fetchOutfits, refreshTrigger]);

  useEffect(() => {
    filterOutfits();
  }, [filterOutfits, selectedCategory, selectedColor, selectedSeason, selectedActivity, selectedBrand]);

  const handleDelete = useCallback(async (ids) => {
    try {
      if (Array.isArray(ids)) {
        await client.post('/api/outfits/delete-multiple/', { ids });
      } else {
        await client.delete(`/api/outfits/${ids}/`);
      }
      fetchOutfits();
      setIsSelecting(false);
      setSelectedItems(new Set());
      toast({
        title: "Success",
        description: "Items deleted successfully",
      });
    } catch (error) {
      console.error('Error deleting outfits:', error);
      toast({
        title: "Error",
        description: "Failed to delete items",
        variant: "destructive",
      });
    }
  }, [fetchOutfits, toast]);

  const toggleItemSelection = useCallback((id) => {
    setSelectedItems(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  }, []);

  const gridSizeClass = {
    2: 'grid-cols-2',
    3: 'grid-cols-3',
    4: 'grid-cols-4',
    6: 'grid-cols-6'
  }[gridSize];

  const handleRemoveFilter = useCallback((type) => {
    handleFilterChange(type, 'All');
  }, [handleFilterChange]);

  const handleDeleteSelected = useCallback(async () => {
    setIsDeleteModalOpen(false);
    await handleDelete(Array.from(selectedItems));
  }, [selectedItems, handleDelete]);

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold">My Closet</h1>
        <div className="flex items-center gap-4">
          <FilterButton
            colors={colors}
            seasons={seasons}
            activities={activities}
            brands={brands}
            selectedColor={selectedColor}
            selectedSeason={selectedSeason}
            selectedActivity={selectedActivity}
            selectedBrand={selectedBrand}
            onFilterChange={handleFilterChange}
          />
          <Button
            onClick={() => {
              setIsSelecting(!isSelecting);
              setSelectedItems(new Set());
            }}
          >
            {isSelecting ? 'Cancel' : 'Select'}
          </Button>
          {isSelecting && selectedItems.size > 0 && (
            <Dialog open={isDeleteModalOpen} onOpenChange={setIsDeleteModalOpen}>
              <DialogTrigger asChild>
                <Button variant="destructive">
                  Delete ({selectedItems.size})
                </Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-md w-[95vw] sm:w-full mx-auto">
                <DialogHeader>
                  <DialogTitle>Delete Selected {selectedItems.size} {selectedItems.size === 1 ? 'item' : 'items'}</DialogTitle>
                </DialogHeader>
                <div className="py-4">
                  <p className="text-gray-600 text-sm sm:text-base">
                    This action cannot be undone!
                  </p>
                </div>
                <div className="flex justify-end gap-4">
                  <button
                    onClick={() => setIsDeleteModalOpen(false)}
                    className="px-3 py-1.5 text-xs sm:text-sm text-gray-600 hover:text-gray-800 transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleDeleteSelected}
                    className="px-3 py-1.5 text-xs sm:text-sm bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
                  >
                    Delete
                  </button>
                </div>
              </DialogContent>
            </Dialog>
          )}
          <GridSizeSelector gridSize={gridSize} onGridSizeChange={setGridSize} />
        </div>
      </div>

      {/* Category Filter */}
      <CategoryFilter
        categories={categories}
        selectedCategory={selectedCategory}
        onSelect={setSelectedCategory}
      />

      {/* Active Filters */}
      <ActiveFilters
        selectedColor={selectedColor}
        selectedSeason={selectedSeason}
        selectedActivity={selectedActivity}
        selectedBrand={selectedBrand}
        onRemove={handleRemoveFilter}
      />

      {/* Grid */}
      {loading ? (
        <div className="text-center py-12">Loading...</div>
      ) : filteredOutfits.length === 0 ? (
        <div className="text-center py-12">
          <h2 className="text-lg font-semibold">Your closet is empty!</h2>
          <Link to="/upload" className="text-blue-500">Add some outfits to get started →</Link>
        </div>
      ) : (
        <div className={`grid ${gridSizeClass} gap-2`}>
          {filteredOutfits.map((outfit) => (
            <motion.div
              key={outfit.id}
              layout
              className="relative"
            >
              <div
                className={`group relative aspect-square bg-white rounded-2xl shadow-sm overflow-hidden cursor-pointer
                  ${isSelecting ? 'hover:opacity-80' : 'hover:shadow-xl'}`}
                onClick={() => {
                  if (isSelecting) {
                    toggleItemSelection(outfit.id);
                  } else {
                    navigate(`/my-closet/${outfit.id}`);
                  }
                }}
              >
                <div className="absolute inset-0 p-2">
                  <img
                    src={outfit.image}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
                {isSelecting && (
                  <div className={`absolute inset-0 flex items-center justify-center
                    ${selectedItems.has(outfit.id) ? 'bg-purple-600/40' : 'bg-black/20'}`}
                  >
                    {selectedItems.has(outfit.id) && (
                      <svg className="w-8 h-8 text-white" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                    )}
                  </div>
                )}
              </div>
            </motion.div>
          ))}
        </div>
      )}
    </div>
  );
};

export default memo(OutfitGallery);
