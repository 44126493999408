// Guided tour steps for each page

export const tourSteps = {
    outfitUpload: {
        step1: {
            target: '.toggle-button',
            content: 'Click here to show or hide the upload section',
            placement: 'bottom',
        },
        step2: {
            target: '.upload-container',
            content: 'Upload max 10 images at a time, Recommended format is .jpg, .jpeg, .png. \n' +
                     'Make sure images are clear, have clear background and are in good lighting.',
            placement: 'bottom'
        },
        step3: {
            target: '.file-count', 
            content: 'Track your uploaded images here',
            placement: 'bottom'
        },
        step4: {
            target: '.trash-icon',
            content: 'Click to clear all selected images',
            placement: 'bottom'
        }
    },
    outfitGallery: {
        step1: {
            target: '.filter-container',
            content: 'Filter your outfits and show/hide columns',
            placement: 'top',
        },
        step2: {
            target: '.w-[140px]',
            content: 'Select a category to filter your outfits',
            placement: 'bottom'
        },
        step3: {
            target: '.bg-white.shadow-lg',
            content: 'View and manage all your outfits here',
            placement: 'top'
        }
    },
    outfitSuggestion: {
        step1: {
            target: '.prompt-textarea',
            content: 'Enter your outfit prompt here. Example: "A casual outfit for a day at the office"',
            placement: 'top',
        },
        step2: {
            target: '.like-button',
            content: 'Like the suggestion to add it to your favorites',
            placement: 'top'
        },
        step3: {
            target: '.clear-history-button',
            content: 'All suggestions made so far are saved. So you won\'t see the same posts again. However, a history that is not cleared for a long time can reduce your options and you may receive poor quality recommendations',
            placement: 'top'
        },
        step4: {
            target: '.lock-button',
            content: 'Lock the item(s) and get new suggestions where other items will be chosen to complement locked item(s)',
            placement: 'top'
        },
        step5: {
            target: '.show-notes-button',
            content: 'Show/hide the AI stylist\'s suggestion notes',
            placement: 'top'
        },
        step6: {
            target: '.get-new-suggestion-button',
            content: 'Get a new suggestion with the same prompt',
            placement: 'top'
        }
    },
    profile: {
        step1: {
            target: '.profile-avatar',
            content: 'Click here to update your profile picture',
            placement: 'bottom',
        },
        step2: {
            target: '.subscription-button',
            content: 'Manage your subscription plan and access premium features',
            placement: 'bottom',
        },
        step3: {
            target: '.edit-profile-button',
            content: 'Edit your profile information',
            placement: 'bottom',
        },
        step4: {
            target: '.change-password-button',
            content: 'Update your password for better security',
            placement: 'bottom',
        },
        step5: {
            target: '.favorites-section',
            content: 'View and manage your favorite outfit combinations',
            placement: 'top',
        }
    }
};