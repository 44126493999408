import React, { useState, useEffect } from 'react';
import { client } from '../axiosClient';
import { useNavigate, useLocation } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import { API_URL } from '../config';

const LoginRegister = ({ setCurrentUser }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const accessToken = params.get('access_token');
    const refreshToken = params.get('refresh_token');
    const error = params.get('error');

    if (error) {
      console.error("Authentication error:", error);
      setErrorMessage(`Authentication failed: ${error}`);
    } else if (accessToken && refreshToken) {
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('refresh_token', refreshToken);
      client.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      fetchUserData();
    }
  }, [location, navigate]);

  const fetchUserData = async () => {
    try {
      const userResponse = await client.get("/api/user/");
      setCurrentUser(userResponse.data.user);
      navigate('/');  // Redirect to home page after successful login
    } catch (error) {
      console.error("Error fetching user data:", error);
      setErrorMessage("Failed to fetch user data. Please try logging in again.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    if (!isLogin && password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    try {
      if (isLogin) {
        const response = await client.post("/api/token/", { email, password });
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
        client.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
        const userResponse = await client.get("/api/user/");
        setCurrentUser(userResponse.data.user);
      } else {
        await client.post("/api/register/", { email, password, password_confirmation: confirmPassword });
        const loginResponse = await client.post("/api/token/", { email, password });
        localStorage.setItem('access_token', loginResponse.data.access);
        localStorage.setItem('refresh_token', loginResponse.data.refresh);
        client.defaults.headers.common['Authorization'] = `Bearer ${loginResponse.data.access}`;
        const userResponse = await client.get("/api/user/");
        setCurrentUser(userResponse.data.user);
      }
    } catch (error) {
      console.error(isLogin ? "Login error:" : "Registration error:", error);
      if (error.response && error.response.data) {
        if (isLogin) {
          // Generic error message for login attempts
          setErrorMessage("Invalid credentials");
        } else {
          // For registration, we can be more specific about validation errors
          if (error.response.data.email) {
            setErrorMessage("This email is already registered");
          } else if (error.response.data.password) {
            setErrorMessage(error.response.data.password[0]);
          } else {
            setErrorMessage("Registration failed. Please try again.");
          }
        }
      } else {
        setErrorMessage("An unexpected error occurred. Please try again later.");
      }
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = `${API_URL}/api/google/`;
  };

  const handleFacebookLogin = () => {
    window.location.href = `${API_URL}/api/facebook/login/`;
  };

  if (showForgotPassword) {
    return <ForgotPassword onBack={() => setShowForgotPassword(false)} />;
  }

  return (
    <div className="flex justify-center mt-12 px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md">
        <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-300 to-purple-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 rounded-3xl"></div>
          <div className="relative bg-white shadow-lg rounded-3xl px-4 py-10 sm:p-20">
            <div>
              <h1 className="text-2xl font-semibold text-center mb-5">{isLogin ? "Login" : "Register"}</h1>
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              {errorMessage && <div className="text-red-500 text-sm text-center">{errorMessage}</div>}
              <div className="space-y-4">
                <div className="relative">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
                    placeholder="Email address"
                    required
                  />
                  <label htmlFor="email" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Email Address</label>
                </div>
                <div className="relative">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
                    placeholder="Password"
                    required
                  />
                  <label htmlFor="password" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Password</label>
                </div>
                {!isLogin && (
                  <div className="relative">
                    <input
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
                      placeholder="Confirm Password"
                      required
                    />
                    <label htmlFor="confirmPassword" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Confirm Password</label>
                  </div>
                )}
              </div>
              <div>
                <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  {isLogin ? "Login" : "Register"}
                </button>
              </div>
            </form>
            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or</span>
                </div>
              </div>
              <div className="mt-6">
                <button
                  onClick={handleGoogleLogin}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mb-2"
                >
                  Google
                </button>
                <button
                  onClick={handleFacebookLogin}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Facebook
                </button>
              </div>
            </div>
            <div className="mt-6 text-center">
              <p className="text-sm text-gray-600">
                {isLogin ? "Don't have an account? " : "Already have an account? "}
                <button
                  onClick={() => {
                    setIsLogin(!isLogin);
                    setErrorMessage('');
                    setPassword('');
                    setConfirmPassword('');
                  }}
                  className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none"
                >
                  {isLogin ? "Register" : "Login"}
                </button>
              </p>
            </div>
            {isLogin && (
              <div className="mt-4 text-center">
                <button
                  onClick={() => setShowForgotPassword(true)}
                  className="text-sm text-blue-600 hover:text-blue-500"
                >
                  Forgot your password?
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginRegister;
