import React, { useState, useEffect, useCallback, memo, useMemo } from 'react';
import { client } from '../axiosClient';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Joyride from 'react-joyride';
import { useStartTour } from '../hooks/startTour';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog";

// Memoized Loading Spinner Component
const LoadingSpinner = memo(() => (
  <span className="flex items-center justify-center">
    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    Generating
  </span>
));

LoadingSpinner.displayName = 'LoadingSpinner';

// Memoized Outfit Image Component
const OutfitImage = memo(({ outfit, isLocked, onLockToggle }) => (
  <div className="relative group aspect-[3/4]">
    <img
      src={outfit.image_url}
      alt={`Suggested outfit ${outfit.id}`}
      className="w-full h-full object-cover rounded-lg shadow-sm transition-transform duration-300 group-hover:scale-[1.02]"
      loading="lazy"
    />
    <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-10 transition-all duration-300 rounded-lg"></div>
    <button
      onClick={() => onLockToggle(outfit.id)}
      className={`lock-button absolute top-2 right-2 p-2 rounded-full ${
        isLocked
          ? 'bg-green-500 text-white'
          : 'bg-white text-gray-600'
      } shadow-md hover:scale-110 transition-all duration-200`}
      aria-label={isLocked ? 'Unlock outfit' : 'Lock outfit'}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        {isLocked ? (
          <path
            fillRule="evenodd"
            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
            clipRule="evenodd"
          />
        ) : (
          <path
            fillRule="evenodd"
            d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
            clipRule="evenodd"
          />
        )}
      </svg>
    </button>
  </div>
));

OutfitImage.displayName = 'OutfitImage';

// Memoized Navigation Controls Component
const NavigationControls = memo(({ canShowPrevious, canShowNext, onPrevious, onNext, currentIndex, totalSuggestions }) => (
  <div className="flex items-center gap-2 text-sm">
    <button
      onClick={onPrevious}
      disabled={!canShowPrevious}
      className="px-3 py-1.5 text-xs sm:text-sm bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
    >
      Previous
    </button>
    <div className="flex justify-center font-semibold text-gray-700">
      {currentIndex + 1} / {totalSuggestions}
    </div>
    <button
      onClick={onNext}
      disabled={!canShowNext}
      className="px-3 py-1.5 text-xs sm:text-sm bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
    >
      Next
    </button>
  </div>
));

NavigationControls.displayName = 'NavigationControls';

// Main Component
const OutfitSuggestion = memo(() => {
  // Form and UI state management
  const [prompt, setPrompt] = useState('');
  const [suggestion, setSuggestion] = useState('');
  const [suggestedOutfits, setSuggestedOutfits] = useState([]);
  const [initialLoading, setInitialLoading] = useState(false);
  const [newSuggestionLoading, setNewSuggestionLoading] = useState(false);
  const [error, setError] = useState('');
  const [showNotes, setShowNotes] = useState(false);
  const [currentCombinationId, setCurrentCombinationId] = useState(null);
  const [isLiked, setIsLiked] = useState(false);
  const [lockedOutfits, setLockedOutfits] = useState(new Set());
  const [userId, setUserId] = useState(null);
  const [allSuggestions, setAllSuggestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { runTour, steps, startTour, config } = useStartTour('outfitSuggestion');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [closetSize, setClosetSize] = useState(0);

  // Memoized handlers
  const handleResize = useCallback(() => {
    // Add resize handler if needed
  }, []);

  const fetchUserId = useCallback(async () => {
    try {
      const response = await client.get('/api/user/');
      setUserId(response.data.user.id);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, []);

  // Clear suggestion history and unliked combinations
  const clearSuggestionHistory = useCallback(async () => {
    try {
      // Clear unliked combinations from the backend
      await client.post('/api/clear-generation-algorithm-records/');
      
      // Clear local storage for current user only
      if (userId) {
        localStorage.removeItem(`outfitSuggestions_${userId}`);
        localStorage.removeItem(`currentSuggestionIndex_${userId}`);
      }
      
      setAllSuggestions([]);
      setCurrentIndex(0);
      setSuggestion('');
      setSuggestedOutfits([]);
      setPrompt('');
      setCurrentCombinationId(null);
      setIsLiked(false);
      setLockedOutfits(new Set());
    } catch (error) {
      console.error('Error clearing unliked combinations:', error);
      setError('Failed to clear suggestion history');
    }
  }, [userId]);

  // Get outfit suggestion from API and update state
  const getSuggestion = useCallback(async (isNewSuggestion = false) => {
    if (isNewSuggestion) {
      setNewSuggestionLoading(true);
    } else {
      setInitialLoading(true);
    }
    setError('');
    
    try {
      // Make API call to get outfit suggestion with locked outfits
      const response = await client.post('/api/outfit-suggestion/', { 
        prompt,
        locked_outfit_ids: Array.from(lockedOutfits)
      });
      
      // Store new suggestion in history with its prompt
      const newSuggestion = {
        suggestion: response.data.suggestion,
        outfits: response.data.suggested_outfits,
        prompt: prompt,
        combinationId: response.data.combination_id,
        isLiked: false
      };
      
      setAllSuggestions(prev => [...prev, newSuggestion]);
      setSuggestion(response.data.suggestion);
      setSuggestedOutfits(response.data.suggested_outfits);
      setCurrentIndex(allSuggestions.length);
      setCurrentCombinationId(response.data.combination_id);
      setIsLiked(false);
    } catch (error) {
      console.error('Error getting outfit suggestion:', error);
      setError('Failed to get outfit suggestion. Please try again.');
    } finally {
      if (isNewSuggestion) {
        setNewSuggestionLoading(false);
      } else {
        setInitialLoading(false);
      }
    }
  }, [prompt, lockedOutfits, allSuggestions.length]);

  // Handle initial suggestion request for new prompt
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    await getSuggestion(false);
  }, [getSuggestion]);

  // Get alternative suggestion for current prompt
  const handleNewSuggestion = useCallback(async () => {
    await getSuggestion(true);
  }, [getSuggestion]);

   // Navigate to previous suggestion in history
  const handlePreviousSuggestion = useCallback(() => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      const previousSuggestion = allSuggestions[newIndex];
      setCurrentIndex(newIndex);
      setSuggestedOutfits(previousSuggestion.outfits);
      setSuggestion(previousSuggestion.suggestion);
      setPrompt(previousSuggestion.prompt);
      setCurrentCombinationId(previousSuggestion.combinationId);
      setIsLiked(previousSuggestion.isLiked);
    }
  }, [currentIndex, allSuggestions]);

  // Navigate to next suggestion in history
  const handleNextSuggestion = useCallback(() => {
    if (currentIndex < allSuggestions.length - 1) {
      const newIndex = currentIndex + 1;
      const nextSuggestion = allSuggestions[newIndex];
      setCurrentIndex(newIndex);
      setSuggestedOutfits(nextSuggestion.outfits);
      setSuggestion(nextSuggestion.suggestion);
      setPrompt(nextSuggestion.prompt);
      setCurrentCombinationId(nextSuggestion.combinationId);
      setIsLiked(nextSuggestion.isLiked);
    }
  }, [currentIndex, allSuggestions]);

  const handleLikeToggle = useCallback(async () => {
    if (!currentCombinationId) return;

    try {
      const response = await client.post(`/api/add-remove-favorite-outfit/${currentCombinationId}/`);
      setIsLiked(response.data.liked);
      
      // Update the like state in allSuggestions
      setAllSuggestions(prev => prev.map((suggestion, index) => {
        if (index === currentIndex) {
          return {
            ...suggestion,
            isLiked: response.data.liked
          };
        }
        return suggestion;
      }));
    } catch (error) {
      console.error('Error toggling like:', error);
      setError('Failed to update like status');
    }
  }, [currentCombinationId, currentIndex]);

  const handleLockToggle = useCallback((outfitId) => {
    setLockedOutfits(prevLocked => {
      const newLocked = new Set(prevLocked);
      if (newLocked.has(outfitId)) {
        newLocked.delete(outfitId);
      } else {
        newLocked.add(outfitId);
      }
      return newLocked;
    });
  }, []);

  // Memoized values
  const isViewingCurrent = useMemo(() => currentIndex === allSuggestions.length - 1, 
    [currentIndex, allSuggestions.length]);
  
  const canShowPrevious = useMemo(() => currentIndex > 0, [currentIndex]);
  
  const canShowNext = useMemo(() => currentIndex < allSuggestions.length - 1, 
    [currentIndex, allSuggestions.length]);

  // Add function to fetch closet size
  const fetchClosetSize = useCallback(async () => {
    try {
      const response = await client.get('/api/outfits/');
      setClosetSize(response.data.length);
    } catch (error) {
      console.error('Error fetching closet size:', error);
    }
  }, []);

  // Modify clearSuggestionHistory to use confirmation
  const handleClearHistory = useCallback(() => {
    setIsConfirmModalOpen(false);
    clearSuggestionHistory();
  }, [clearSuggestionHistory]);

  // Effects
  useEffect(() => {
    fetchUserId();
  }, [fetchUserId]);

  useEffect(() => {
    if (userId) {
      const savedSuggestions = localStorage.getItem(`outfitSuggestions_${userId}`);
      const savedIndex = localStorage.getItem(`currentSuggestionIndex_${userId}`);
      
      if (savedSuggestions) {
        setAllSuggestions(JSON.parse(savedSuggestions));
        setCurrentIndex(savedIndex ? parseInt(savedIndex) : 0);
      }
    } else {
      setAllSuggestions([]);
      setCurrentIndex(0);
      setSuggestion('');
      setSuggestedOutfits([]);
      setPrompt('');
      setCurrentCombinationId(null);
      setIsLiked(false);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      localStorage.setItem(`outfitSuggestions_${userId}`, JSON.stringify(allSuggestions));
      localStorage.setItem(`currentSuggestionIndex_${userId}`, currentIndex.toString());
    }
  }, [allSuggestions, currentIndex, userId]);

  useEffect(() => {
    if (allSuggestions.length > 0 && userId) {
      const currentSuggestion = allSuggestions[currentIndex];
      setSuggestion(currentSuggestion.suggestion);
      setSuggestedOutfits(currentSuggestion.outfits);
      setPrompt(currentSuggestion.prompt);
      setCurrentCombinationId(currentSuggestion.combinationId);
      setIsLiked(currentSuggestion.isLiked);
    }
  }, [allSuggestions, currentIndex, userId]);

  useEffect(() => {
    const checkLikeStatus = async () => {
      if (!currentCombinationId) return;

      try {
        const response = await client.get(`/api/add-remove-favorite-outfit/${currentCombinationId}/`);
        setIsLiked(response.data.liked);
        
        setAllSuggestions(prev => prev.map((suggestion, index) => {
          if (index === currentIndex) {
            return {
              ...suggestion,
              isLiked: response.data.liked
            };
          }
          return suggestion;
        }));
      } catch (error) {
        console.error('Error checking like status:', error);
      }
    };

    checkLikeStatus();
  }, [currentCombinationId, currentIndex]);

  useEffect(() => {
    const hasSeenTour = localStorage.getItem('hasSeenOutfitSuggestionTour');
    if (!hasSeenTour) {
      startTour();
      localStorage.setItem('hasSeenOutfitSuggestionTour', 'true');
    }
  }, [startTour]);

  useEffect(() => {
    fetchClosetSize();
  }, [fetchClosetSize]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if (status === 'finished' || status === 'skipped') {
      localStorage.setItem('hasSeenOutfitSuggestionTour', 'true');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8">
      <Joyride
        {...config}
        run={runTour}
        steps={steps}
        callback={handleJoyrideCallback}
      />
      <div className="max-w-6xl mx-auto mt-10 px-4">
        <h2 className="text-2xl font-semibold mb-6">Generate Outfits</h2>
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
          <form onSubmit={handleSubmit} className="flex-1 w-full">
            <div className="relative flex flex-col sm:block gap-2">
              <textarea
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="I need an outfit for the first date..."
                rows={Math.min(3, Math.max(1, prompt.split('\n').length))}
                className="prompt-textarea w-full p-4 border border-gray-200 rounded-[24px] shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all resize-none sm:pr-[140px]"
                style={{
                  minHeight: '52px',
                  height: 'auto',
                  maxHeight: '100px'
                }}
              />
              <button
                type="submit"
                disabled={!prompt || initialLoading || newSuggestionLoading || closetSize === 0}
                className="w-full sm:w-auto sm:absolute sm:right-2 sm:top-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white px-6 py-2 rounded-full hover:from-purple-500 hover:to-blue-600 disabled:from-gray-400 disabled:to-gray-500 transition-all duration-300 shadow-sm"
              >
                {initialLoading ? <LoadingSpinner /> : 'Generate'}
              </button>
              {closetSize === 0 && (
                <p className="text-red-500 text-sm mt-2">
                  Please add items to your closet to generate outfits
                </p>
              )}
              {closetSize > 0 && closetSize < 30 && (
                <p className="text-yellow-600 text-sm mt-2">
                  Add more items to get more accurate suggestions ({closetSize}/30 items)
                </p>
              )}
            </div>
          </form>          
        </div>

        {/* Suggestion display section */}
        {(suggestion || allSuggestions.length > 0) && (
          <div className="mt-4 sm:mt-6 bg-white rounded-xl shadow-md overflow-hidden">
            <div className="p-3 sm:p-6">
              {/* Header with navigation and like controls */}
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-3 mb-4">
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => setShowNotes(!showNotes)}
                    className="text-xs sm:text-sm text-blue-600 hover:text-blue-700 flex items-center gap-1"
                  >
                    {showNotes ? 'Hide Notes' : 'Show Notes'}
                    <svg
                      className={`w-3 h-3 sm:w-4 sm:h-4 transition-transform duration-200 ${showNotes ? 'rotate-180' : ''}`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                </div>
                <div className="flex items-center gap-2">
                  {/* Like button */}
                  {currentCombinationId && (
                    <button
                      onClick={handleLikeToggle}
                      className="like-button focus:outline-none transform transition-transform duration-200 hover:scale-110"
                    >
                      <svg
                        className={`w-6 h-6 sm:w-8 sm:h-8 ${isLiked ? 'text-red-500 fill-current' : 'text-gray-400 stroke-current'}`}
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={isLiked ? "0" : "2"}
                        fill={isLiked ? "currentColor" : "none"}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                        />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
              
              {/* Outfit images grid */}
              {suggestedOutfits.length > 0 && (
                <>
                  <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-2 sm:gap-4 mb-4 sm:mb-6">
                    {suggestedOutfits.map((outfit, index) => (
                      <OutfitImage
                        key={index}
                        outfit={outfit}
                        isLocked={lockedOutfits.has(outfit.id)}
                        onLockToggle={handleLockToggle}
                      />
                    ))}
                  </div>

                  {/* AI suggestion notes */}
                  {showNotes && (
                    <div className="bg-gray-50 p-3 sm:p-4 rounded-lg mb-4 text-sm sm:text-base">
                      <ReactMarkdown 
                        children={suggestion} 
                        remarkPlugins={[remarkGfm]}
                        className="prose max-w-none text-sm sm:text-base"
                      />
                      <br />
                      <p className="text-sm sm:text-base"> <strong>Prompt:</strong> {prompt}</p>
                    </div>
                  )}

                  {/* Navigation and regenerate controls */}
                  <div className="flex flex-col sm:flex-row justify-between items-center gap-3">
                    <div className="flex items-center gap-2 w-full sm:w-auto justify-between sm:justify-start">
                      <NavigationControls
                        canShowPrevious={canShowPrevious}
                        canShowNext={canShowNext}
                        onPrevious={handlePreviousSuggestion}
                        onNext={handleNextSuggestion}
                        currentIndex={currentIndex}
                        totalSuggestions={allSuggestions.length}
                      />
                      {/* Clear History button with confirmation modal */}
                      {allSuggestions.length > 0 && (
                        <Dialog open={isConfirmModalOpen} onOpenChange={setIsConfirmModalOpen}>
                          <DialogTrigger asChild>
                            <button className="clear-history-button px-2 py-1 text-xs bg-gradient-to-r from-red-500 to-red-600 text-white rounded-full hover:from-red-600 hover:to-red-700 transition-all duration-300 flex items-center justify-center gap-1 whitespace-nowrap shadow-sm">
                              <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="12" 
                                height="12" 
                                viewBox="0 0 24 24" 
                                fill="none" 
                                stroke="currentColor" 
                                strokeWidth="2" 
                                strokeLinecap="round" 
                                strokeLinejoin="round"
                                className="sm:w-4 sm:h-4"
                              >
                                <path d="M3 6h18"></path>
                                <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                                <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                              </svg>
                              <span className="hidden sm:inline">Clear History</span>
                            </button>
                          </DialogTrigger>
                          <DialogContent className="sm:max-w-md w-[95vw] sm:w-full mx-auto">
                            <DialogHeader>
                              <DialogTitle>Clear Suggestion History</DialogTitle>
                            </DialogHeader>
                            <div className="py-4">
                              <p className="text-gray-600 text-sm sm:text-base">
                                This action cannot be undone.
                              </p>
                            </div>
                            <div className="flex justify-end gap-4">
                              <button
                                onClick={() => setIsConfirmModalOpen(false)}
                                className="px-3 py-1.5 text-xs sm:text-sm text-gray-600 hover:text-gray-800 transition-colors"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={handleClearHistory}
                                className="px-3 py-1.5 text-xs sm:text-sm bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
                              >
                                Clear History
                              </button>
                            </div>
                          </DialogContent>
                        </Dialog>
                      )}
                    </div>
                    <button
                      onClick={handleNewSuggestion}
                      disabled={initialLoading || newSuggestionLoading}
                      className="w-full sm:w-auto flex items-center justify-center gap-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white px-4 sm:px-6 py-2 rounded-full hover:from-purple-500 hover:to-blue-600 disabled:from-gray-400 disabled:to-gray-500 transition-all duration-300 shadow-sm text-sm"
                    >
                      <svg 
                        className={`w-3 h-3 sm:w-4 sm:h-4 ${newSuggestionLoading ? 'animate-spin' : ''}`}
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                      </svg>
                      {newSuggestionLoading ? 'Regenerating...' : 'Regenerate'}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {/* Error display */}
        {error && (
          <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-lg">
            <p className="text-red-600">{error}</p>
          </div>
        )}
      </div>
    </div>
  );
});

OutfitSuggestion.displayName = 'OutfitSuggestion';

export default OutfitSuggestion;
